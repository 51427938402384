import { DATES_AND_STATES_STEP_PATH, OVERVIEW_STEP_PATH, PERILS_STEP_PATH } from '~/components/claims-admin/cat-tag-utils'

export default () => ({
  mode: '',
  tagUuid: '',
  activePerils: [],
  [OVERVIEW_STEP_PATH]: {
    formData: {
      description: '',
      other: '',
    },
  },
  [DATES_AND_STATES_STEP_PATH]: {
    formData: {
      type: '',
      other2: '',
      locationDates: [],
    },
  },
  [PERILS_STEP_PATH]: {
    formData: {
      pcsNumber: '',
      perilUuids: [],
    },
  },
  // TODO: remove this from state files once fetch data for Edit Flow is connected
  loadedData: false,
})
