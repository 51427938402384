import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { OVERVIEW_STEP_PATH, DATES_AND_STATES_STEP_PATH, PERILS_STEP_PATH, REVIEW_STEP_PATH } from '~/components/claims-admin/cat-tag-utils.js'

/**
 * Holds vuelidate instance outside of composable function to persist across re-renders.
 */
let v$ = null

/**
 * Track if entire form was validated.
 */
const isFormValidated = ref(false)

/**
 * Composable to manage form validation for Cat Tags.
 * - Uses a single Vuelidate instance for all steps.
 * - Ensures `v$` persists across navigation in create & edit pages.
 * - Supports single field, step and full-form validation.
 * - Watches for changes in current step path and validates the previous step when navigating.
 * - Resets validation and clears state when leaving the flow.
 * - Computes whether a tab should be disabled based on validation state.
 * - Computes whether a tab should display a warning icon (invalid + dirty).
 * - Computes whether a tab should display a success icon (valid + dirty)
 */
export default function useCatTagValidation() {
  const { $store } = useNuxtApp()
  const router = useRouter()

  /**
   * Validation Rules for all steps
   */
  const validationRules = {
    [OVERVIEW_STEP_PATH]: {
      description: { required },
      other: { required },
    },
    [DATES_AND_STATES_STEP_PATH]: {
      type: { required },
    },
    [PERILS_STEP_PATH]: {
      pcsNumber: { required },
    },
  }

  /**
   * Reactive computed form structure for all steps (excluding the review step).
   */
  const form = computed(() => ({
    [OVERVIEW_STEP_PATH]: $store.state.adminClaims[OVERVIEW_STEP_PATH].formData,
    [DATES_AND_STATES_STEP_PATH]: $store.state.adminClaims[DATES_AND_STATES_STEP_PATH].formData,
    [PERILS_STEP_PATH]: $store.state.adminClaims[PERILS_STEP_PATH].formData,
  }))

  /**
   * Single Vuelidate instance for all steps. Initialize v$ once to persist across navigation.
   */
  if (v$ === null) {
    v$ = useVuelidate(validationRules, form)
  }

  /**
   * Computed property to get the current step from the router.
   */
  const currentStepPath = computed(() => router.currentRoute.value.params.stepName)

  /**
   * Resets the validation instance (`v$`).
   * This should be called when the user exits the CAT Tag step flow.
   */
  const resetFormValidation = () => {
    v$ = null
    isFormValidated.value = false
    $store.dispatch('adminClaims/clearApplicationState')
  }

  /**
   * Validates entire form (aka all steps).
   * Updates `dirty` and `invalid` values for each step when validation is complete.
   */
  const validateEntireForm = async() => {
    await v$.value.$validate()
    isFormValidated.value = true
  }

  /**
   * Retrieves the validation object for a specific step.
   * @param {String} stepPath - The path of the step in url (based on constants defined in utils).
   * @returns {Object} The Vuelidate validation object for the specified step.
   */
  const getStepValidationObj = (stepPath) => {
    return v$?.value[stepPath]
  }

  /**
   * Checks if a specific step is marked as dirty (if the user has interacted with it).
   * @param {String} stepPath - The path of the step in url (based on constants defined in utils)
   * @returns {Boolean} 'true if the step is dirty, 'false' otherwise.
   */
  const isStepDirty = (stepPath) => { return v$?.value[stepPath]?.$dirty || false }

  /**
   * Checks if a specific step is valid (passes all validation rules).
   * @param {String} stepPath - The path of the step in url (based on constants defined in utils)
   * @returns {Boolean} 'true' if the step is valid, 'false' otherwise.
   */
  const isStepValid = (stepPath) => { return !v$?.value[stepPath]?.$invalid || false }

  /**
   * Validates a specific step and returns whether it is valid.
   * @param {String} stepPath - The path of the step in url (based on constants defined in utils)
   * @returns {Boolean} 'true' if the step is valid, 'false' otherwise.
   */
  const validateStep = async(stepPath) => {
    const stepValidationObj = getStepValidationObj(stepPath)
    if (stepValidationObj) {
      await v$.value[stepPath].$validate()
      return isStepValid(stepPath)
    }
    // step has no validation so return true
    return true
  }

  /**
   * Retrieves validation errors for a specific field. This is used to display error messages in UI.
   * @param {String} stepPath - The path of the step in url (based on constants defined in utils).
   * @param {String} fieldName - The name of the field to check. Name based on formData keys in state.
   * @returns {Array<Object>} Array of validation error objects for the field.
   */
  const getFieldErrors = (stepPath, fieldName) => {
    const stepValidationObj = getStepValidationObj(stepPath)
    return stepValidationObj?.[fieldName]?.$errors || []
  }

  /**
   * Validates a specific field within a step.
   * @param {String} stepPath - The path of the step in url (based on constants defined in utils).
   * @param {String} fieldName - The name of the field to validate. Name based on formData keys in state.
   * @returns {void} Awaits validation for field.
   */
  const validateStepField = async(stepPath, fieldName) => {
    const stepValidationObj = getStepValidationObj(stepPath)
    if (stepValidationObj?.[fieldName]) {
      await stepValidationObj[fieldName].$validate()
    }
  }

  // Tab Validation
  /**
   * Determines whether a step's tab should be disabled.
   * - Dates & States and Perils steps are  disabled if Overview Step is invalid.
   * - Review Step is disabled if any of the other steps are invalid
   * @param {String} stepPath - The path of the step in url (based on constants defined in utils).
   * @returns {ComputedRef<Boolean>} 'true' if tab should be disabled, otherwise 'false'.
   */
  const isStepTabDisabled = stepPath => computed(() => {
    switch (stepPath) {
      case DATES_AND_STATES_STEP_PATH:
      case PERILS_STEP_PATH:
        return !isStepValid(OVERVIEW_STEP_PATH)
      case REVIEW_STEP_PATH:
        return !isStepValid(OVERVIEW_STEP_PATH) || !isStepValid(DATES_AND_STATES_STEP_PATH) || !isStepValid(PERILS_STEP_PATH)
      default:
        return false
    }
  })

  /**
   * Computed property that determines whether to show a warning icon for a step tab.
   * A warning icon is shown if the step is invalid and has been interacted with.
   * @param {String} stepPath - The path of the step in url (based on constants defined in utils).
   * @returns {ComputedRef<Boolean>} A computed ref that returns true if the warning icon should be shown, otherwise 'false'.
   */
  const showStepTabWarning = stepPath => (computed(() => {
    return !isStepValid(stepPath) && isStepDirty(stepPath)
  }))

  /**
   * Computed property that determines whether to show a success icon for a step tab.
   * A success icon is shown if the step is valid and has been interacted with.
   * @param {String} stepPath - The path of the step in url (based on constants defined in utils).
   * @returns {ComputedRef<Boolean>} A computed ref that returns true if the success icon should be shown, otherwise `false`.
   */
  const showStepTabSuccess = stepPath => (computed(() => {
    return isStepValid(stepPath) && isStepDirty(stepPath)
  }))

  /**
   * Watches for changes in the current step path and validates the previous step when navigating.
   * If the user navigates away from the step flow, form validation is reset.
   */
  watch(currentStepPath, async(newStepPath, oldStepPath) => {
    if (v$ && oldStepPath && newStepPath) {
      await validateStep(oldStepPath)
    }
    if (!newStepPath) {
      resetFormValidation()
    }
  })

  return {
    v$,
    isFormValidated,
    validateEntireForm,
    validateStepField,
    getFieldErrors,
    isStepTabDisabled,
    showStepTabWarning,
    showStepTabSuccess,
  }
}
