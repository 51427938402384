<template>
  <div>
    <kudo-form-control
      class="lg:w-1/2"
      :errors="$vuelidateErrorsToStrings(perilsTextErrors)"
    >
      <kudo-input
        v-model="perilsText"
        label-text="Perils text"
        required
      />
    </kudo-form-control>
  </div>
</template>

<script setup>
import { KudoFormControl, KudoInput } from '@openly-engineering/kudo'
import { PERILS_STEP_PATH } from './cat-tag-utils'

const { $store } = useNuxtApp()
const { getFieldErrors } = useCatTagValidation()

// Note: Will be updated to match fields expected for step
const perilsText = computed({
  get() { return $store.state.adminClaims[PERILS_STEP_PATH].formData.pcsNumber },
  set(val) { $store.commit('adminClaims/setPcsNumber', val) },
})

const perilsTextErrors = computed(() => getFieldErrors(PERILS_STEP_PATH, 'pcsNumber'))
</script>
