import { DATES_AND_STATES_STEP_PATH, OVERVIEW_STEP_PATH, PERILS_STEP_PATH } from '~/components/claims-admin/cat-tag-utils'

export default {
  setMode(state, mode) {
    state.mode = mode
  },
  setLoadedData(state, value) {
    state.loadedData = value
  },
  setTagUuid(state, value) {
    state.tagUuid = value
  },
  setType(state, value) {
    state[DATES_AND_STATES_STEP_PATH].formData.type = value
  },
  setPcsNumber(state, value) {
    state[PERILS_STEP_PATH].formData.pcsNumber = value
  },
  setDescription(state, value) {
    state[OVERVIEW_STEP_PATH].formData.description = value
  },
  // TODO: Remove this once correct form fields are added to steps.
  setOther(state, value) {
    state[OVERVIEW_STEP_PATH].formData.other = value
  },
  // TODO: Remove this once correct form fields are added to steps.
  setOther2(state, value) {
    state[DATES_AND_STATES_STEP_PATH].formData.other2 = value
  },
  setActivePerils(state, value) {
    state.activePerils = value
  },
  resetActivePerils(state) {
    state.activePerils = []
  },
  // locationDates mutations
  addLocationDate(state, locationDate) {
    state[DATES_AND_STATES_STEP_PATH].formData.locationDates.push(locationDate)
  },
  updateLocationDate(state, newLocationDate) {
    const locationIndex = state[DATES_AND_STATES_STEP_PATH].formData.locationDates.findIndex((locationDate) => {
      return locationDate.id === newLocationDate.id
    })

    if (locationIndex !== -1) {
      state.locationDates[locationIndex] = { ...newLocationDate }
    }
  },
  removeLocationDate(state, locationDateToRemove) {
    state[DATES_AND_STATES_STEP_PATH].formData.locationDates.filter((locationDate) => {
      return locationDate.id !== locationDateToRemove.id
    })
  },
  resetLocationDates(state) {
    state[DATES_AND_STATES_STEP_PATH].formData.locationDates = []
  },
  // perilUuids mutations
  addPerilUuid(state, perilUuid) {
    state[PERILS_STEP_PATH].formData.perilUuids.push(perilUuid)
  },
  removePerilUuid(state, perilUuid) {
    state[PERILS_STEP_PATH].formData.perilUuids.filter((uuid) => { return uuid !== perilUuid })
  },
  resetPerilUuids(state) {
    state[PERILS_STEP_PATH].formData.perilUuids = []
  },
}
