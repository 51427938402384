export default {
  clearApplicationState({ commit }) {
    commit('resetLocationDates')
    commit('resetPerilUuids')
    commit('resetActivePerils')
    commit('setMode', '')
    commit('setTagUuid', '')
    commit('setType', '')
    commit('setPcsNumber', '')
    commit('setDescription', '')
    commit('setLoadedData', false)
    // TODO: Remove below once form fields are added to steps
    commit('setOther', '')
    commit('setOther2', '')
  },
}
