<template>
  <div>
    <kudo-form-control
      class="lg:w-1/2"
      :errors="$vuelidateErrorsToStrings(dateTextErrors)"
    >
      <kudo-input
        v-model="dateText"
        label-text="Date text"
        required
      />
    </kudo-form-control>
    <kudo-form-control
      class="lg:w-1/2"
    >
      <kudo-input
        v-model="other2"
        label-text="other2 text"
      />
    </kudo-form-control>
  </div>
</template>

<script setup>
import { KudoFormControl, KudoInput } from '@openly-engineering/kudo'
import { DATES_AND_STATES_STEP_PATH } from './cat-tag-utils'

const { $store } = useNuxtApp()
const { getFieldErrors } = useCatTagValidation()

// Note: Will be updated to match fields expected for step
const dateText = computed({
  get() { return $store.state.adminClaims[DATES_AND_STATES_STEP_PATH].formData.type },
  set(val) { $store.commit('adminClaims/setType', val) },
})

const other2 = computed({
  get() { return $store.state.adminClaims[DATES_AND_STATES_STEP_PATH].formData.other2 },
  set(val) { $store.commit('adminClaims/setOther2', val) },
})

const dateTextErrors = computed(() => getFieldErrors(DATES_AND_STATES_STEP_PATH, 'type'))
</script>
