<template>
  <div>
    <kudo-form-control
      class="lg:w-1/2"
      :errors="$vuelidateErrorsToStrings(descriptionErrors)"
    >
      <kudo-input
        v-model="description"
        label-text="Overview text"
        required
      />
    </kudo-form-control>
    <kudo-form-control
      class="lg:w-1/2"
      :errors="$vuelidateErrorsToStrings(otherErrors)"
    >
      <kudo-input
        v-model="other"
        label-text="other text"
        required
      />
    </kudo-form-control>
  </div>
</template>

<script setup>
import { KudoFormControl, KudoInput } from '@openly-engineering/kudo'
import { OVERVIEW_STEP_PATH } from './cat-tag-utils'
import useCatTagValidation from '~/composables/useCatTagValidation'

const { $store } = useNuxtApp()
const { getFieldErrors } = useCatTagValidation()

// Note: Will be updated to match fields expected for step
const description = computed({
  get() { return $store.state.adminClaims[OVERVIEW_STEP_PATH].formData.description },
  set(val) { $store.commit('adminClaims/setDescription', val) },
})

const other = computed({
  get() { return $store.state.adminClaims[OVERVIEW_STEP_PATH].formData.other },
  set(val) { $store.commit('adminClaims/setOther', val) },
})

const descriptionErrors = computed(() => getFieldErrors(OVERVIEW_STEP_PATH, 'description'))
const otherErrors = computed(() => getFieldErrors(OVERVIEW_STEP_PATH, 'other'))
</script>
